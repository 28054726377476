import React from "react"
import Layout from "../components/layout/"
import { PhoneIcon, MailIcon } from "../components/icons"

const Contact = () => (
  <Layout>
    <div className="container container-main">
      <h1>Contact</h1>
      <h3>Email</h3>
      <p>
        <MailIcon />
        &nbsp;
        <a href="mailto:hello@damoorikitchen.com">hello@damoorikitchen.com</a>
      </p>
      <h3>Phone</h3>
      <p>
        <PhoneIcon />
        &nbsp;(206)-257-5947
      </p>
      <h3>Mailing address</h3>
      <p>
        Damoori Kitchen
        <br />
        P.O. Box 99524
        <br />
        Seattle, WA 98139-0524
      </p>
      <h3>Physical address</h3>
      <p>
        Damoori Kitchen
        <br />
        3101 W Jameson St
        <br />
        Seattle, WA 98199
      </p>
    </div>
  </Layout>
)

export default Contact
